import { n as normalizeComponent, F as FasHeader } from "./index.js";
import { MAINTAIN_PERMISSION, FAS_REGISTER } from "@kraftheinz/common";
var render$3 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("a-card", {
    staticClass: "financial-year",
    attrs: {
      "title": "Financial Year",
      "head-style": {
        padding: "0px 32px",
        borderBottom: 0,
        fontSize: "14px"
      },
      "body-style": {
        paddingTop: 0,
        height: "calc(100vh - 214px)",
        overflow: "auto"
      }
    }
  }, [_c("a-button", {
    attrs: {
      "slot": "extra",
      "size": "small",
      "disabled": !_vm.can(_vm.permissions.maintain)
    },
    on: {
      "click": _vm.onCreateFinancialYear
    },
    slot: "extra"
  }, [_vm._v(" + ")]), _c("a-menu", {
    attrs: {
      "mode": "inline",
      "theme": "light",
      "default-selected-keys": [_vm.defaultYear]
    }
  }, [_vm._l(_vm.listYear, function(item) {
    return [_c("a-menu-item", {
      key: item,
      on: {
        "click": function($event) {
          return _vm.clickItem($event);
        }
      }
    }, [_vm._v(" " + _vm._s(item) + " ")])];
  })], 2)], 1);
};
var staticRenderFns$3 = [];
var FinancialYearList_vue_vue_type_style_index_0_scoped_true_lang = "";
const __vue2_script$3 = {
  name: "FinancialYearList",
  inject: ["crud", "apiUrl", "createRoute", "can"],
  data() {
    return {
      selectedKey: "",
      permissions: {
        maintain: MAINTAIN_PERMISSION
      }
    };
  },
  computed: {
    listYear() {
      const list = this.crud.getList();
      let result = list.map(({ year }) => year);
      return result;
    },
    defaultYear() {
      return this.$moment().year();
    }
  },
  created() {
    this.crud.deleteFilter("IsInactive");
    this.crud.deletePagination();
    this.crud.fetchList();
    this.$emit("change", this.defaultYear);
  },
  methods: {
    clickItem(e) {
      this.selectedKey = e.key;
      this.$emit("change", e.key);
    },
    onCreateFinancialYear() {
      this.$router.push(this.createRoute);
    }
  }
};
const __cssModules$3 = {};
var __component__$3 = /* @__PURE__ */ normalizeComponent(__vue2_script$3, render$3, staticRenderFns$3, false, __vue2_injectStyles$3, "3061e06a", null, null);
function __vue2_injectStyles$3(context) {
  for (let o in __cssModules$3) {
    this[o] = __cssModules$3[o];
  }
}
var FinancialYearList$1 = /* @__PURE__ */ function() {
  return __component__$3.exports;
}();
var render$2 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("resource", {
    attrs: {
      "name": "fas.financials",
      "api-url": _vm.apiUrl,
      "create-route": "/fixed-asset-register/financial-year/create",
      "page": _vm.page
    }
  }, [_c("financial-year-list", {
    on: {
      "change": _vm.onFinancialYearChange
    }
  })], 1)], 1);
};
var staticRenderFns$2 = [];
const apiUrl$2 = "#{VUE_APP_API_URL}#";
const __vue2_script$2 = {
  components: { FinancialYearList: FinancialYearList$1 },
  data() {
    return {
      FinancialYearList: FinancialYearList$1,
      page: FAS_REGISTER,
      apiUrl: apiUrl$2
    };
  },
  methods: {
    onFinancialYearChange(e) {
      this.$emit("change", e);
    }
  }
};
const __cssModules$2 = {};
var __component__$2 = /* @__PURE__ */ normalizeComponent(__vue2_script$2, render$2, staticRenderFns$2, false, __vue2_injectStyles$2, null, null, null);
function __vue2_injectStyles$2(context) {
  for (let o in __cssModules$2) {
    this[o] = __cssModules$2[o];
  }
}
var FinancialYearList = /* @__PURE__ */ function() {
  return __component__$2.exports;
}();
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "fixed-asset-register"
  }, [_c("fas-header", {
    attrs: {
      "title": "Fixed Asset Register",
      "create-button-text": "Create New Fixed Asset Register",
      "create-route": "/fixed-asset-register/create",
      "disable-create-button": !_vm.can(_vm.permissions.maintain)
    }
  }), _c("div", {
    staticClass: "fixed-asset-register__content"
  }, [_c("a-row", {
    attrs: {
      "gutter": 24
    }
  }, [_c("a-col", {
    attrs: {
      "span": 7
    }
  }, [_c("financial-year-list", {
    on: {
      "change": function($event) {
        return _vm.onYearChange($event);
      }
    }
  })], 1), _c("a-col", {
    attrs: {
      "span": 17
    }
  }, [_c("div", {
    staticClass: "fixed-asset-register__filter d-flex justify-content-end px-5 pt-2"
  }, [_c("keyword-filter", {
    attrs: {
      "field": "FacilityName",
      "placeholder": "Search by keyword"
    }
  })], 1), _c("a-table", {
    staticClass: "mr-5 mt-3",
    attrs: {
      "bordered": "",
      "columns": _vm.columns,
      "data-source": _vm.dataSource
    },
    scopedSlots: _vm._u([{
      key: "nation",
      fn: function(record) {
        return _c("span", {
          staticClass: "url-field"
        }, [_vm._v(" " + _vm._s(record.countryName) + " (" + _vm._s(record.facilityCount) + ") ")]);
      }
    }, {
      key: "assetCount",
      fn: function(text) {
        return _c("p", {
          staticClass: "m-0"
        }, [_vm._v(" " + _vm._s("Total (" + text.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + ")") + " ")]);
      }
    }, {
      key: "expandedRowRender",
      fn: function(record) {
        return _c("a-table", {
          staticClass: "inner-table",
          attrs: {
            "data-source": record.assetRegisterFacilitys,
            "columns": _vm.facilityColumns,
            "show-header": false,
            "pagination": false
          },
          scopedSlots: _vm._u([{
            key: "facilities",
            fn: function(facility) {
              return [_c("span", {
                staticClass: "url-field",
                style: {
                  cursor: "pointer"
                },
                on: {
                  "click": function($event) {
                    return _vm.onFacilityClick(facility);
                  }
                }
              }, [_vm._v(" " + _vm._s(facility.facilityName) + " ")])];
            }
          }, {
            key: "status",
            fn: function(text) {
              return [text === true ? _c("a-tag", {
                attrs: {
                  "color": "cyan"
                }
              }, [_vm._v("Open")]) : _c("a-tag", [_vm._v("Closed")])];
            }
          }, {
            key: "action",
            fn: function(facility) {
              return [_vm.can(_vm.permissions.maintain) ? _c("a-tooltip", {
                scopedSlots: _vm._u([{
                  key: "title",
                  fn: function() {
                    return [_vm._v("Edit")];
                  },
                  proxy: true
                }], null, true)
              }, [_c("a-button", {
                staticClass: "list__action-toggle-active mr-1",
                attrs: {
                  "icon": "edit",
                  "size": "small",
                  "type": "link"
                },
                on: {
                  "click": function($event) {
                    return _vm.onEditClick(facility);
                  }
                }
              })], 1) : _vm._e()];
            }
          }])
        });
      }
    }])
  })], 1)], 1)], 1)], 1);
};
var staticRenderFns$1 = [];
var FixedAssetRegister_vue_vue_type_style_index_0_lang = "";
const apiUrl$1 = "#{VUE_APP_API_URL}#";
const __vue2_script$1 = {
  name: "FixedAssetRegister",
  components: {
    FasHeader,
    FinancialYearList
  },
  inject: ["crud", "can"],
  data() {
    return {
      apiUrl: apiUrl$1,
      FasHeader,
      FinancialYearList,
      fields: "",
      financialYear: "",
      permissions: {
        maintain: MAINTAIN_PERMISSION
      },
      columns: [
        {
          title: "Facilities",
          dataIndex: "",
          key: "name",
          scopedSlots: { customRender: "nation" }
        },
        {
          title: "Status",
          dataIndex: "",
          key: "status",
          scopedSlots: { customRender: "status" },
          align: "center",
          width: 80
        },
        {
          title: "Assets Count",
          dataIndex: "assetCount",
          key: "assetCount",
          scopedSlots: { customRender: "assetCount" },
          align: "right",
          width: 110
        },
        {
          title: "Action",
          dataIndex: "",
          key: "x",
          scopedSlots: { customRender: "action" },
          align: "center",
          width: 70
        }
      ],
      facilityColumns: [
        {
          title: "",
          dataIndex: "",
          key: "name",
          scopedSlots: { customRender: "facilities" }
        },
        {
          title: "",
          dataIndex: "status",
          key: "status",
          scopedSlots: { customRender: "status" },
          align: "center",
          width: 80
        },
        {
          title: "",
          dataIndex: "assetCount",
          key: "assetCount",
          scopedSlots: { customRender: "assetCount" },
          align: "right",
          width: 110
        },
        {
          title: "",
          dataIndex: "",
          key: "x",
          scopedSlots: { customRender: "action" },
          align: "center",
          width: 70
        }
      ]
    };
  },
  computed: {
    dataSource() {
      return this.crud.getList();
    }
  },
  methods: {
    onFacilityClick(record) {
      const { id, status, fullName } = record;
      this.$router.push({
        path: "/assets",
        query: {
          id,
          status,
          fullName
        }
      });
    },
    onYearChange(e) {
      this.crud.setFilter("Year", { operator: "Eq", value: e });
      this.crud.deleteFilter("IsInactive");
      this.crud.fetchList();
    },
    onEditClick(record) {
      this.$router.push(`/fixed-asset-register/${record.id}`);
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var FixedAssetRegister = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("bread-crumb", {
    attrs: {
      "items": _vm.itemsMenu
    }
  }), _c("resource", {
    attrs: {
      "name": "fas.assetregisters.getlistgroup",
      "api-url": _vm.apiUrl,
      "create-route": "/fixed-asset-register/create",
      "page": _vm.page
    }
  }, [_c("fixed-asset-register")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    FixedAssetRegister
  },
  data() {
    return {
      FixedAssetRegister,
      apiUrl,
      page: FAS_REGISTER,
      itemsMenu: [
        {
          key: "fixedAssetRegister",
          title: "Fixed Asset Register",
          path: ""
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
